import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const adminSlice = createSlice({
  name: "contactUs",
  initialState: {
    dropdowns: {},
    refetch: false,
  },
  reducers: {
    getAllDropdownListSuccess: (state, action) => {
      state.dropdowns = action.payload;
    },
    // getContactUsDetailsSuccess: (state, action) => {
    //   state.contactDetails = action.payload;
    // },
    refetch: (state, action) => {
      state.refetch = action.payload;
    },
  },
});

export const { getAllDropdownListSuccess, refetch } = adminSlice.actions;

export const getAllDropdowns = (setIsLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/dropdown/get/all/dropdown/v1`;
    const { data } = await axios.get(url, {
      headers: {
        Authorization: localStorage.getItem("vctoken"),
      },
    });
    dispatch(getAllDropdownListSuccess(data.data));
    setIsLoading(false);
  } catch (error) {
    console.log(error);
    setIsLoading(false);
  }
};

export const createGenderDropdown =
  (gender, setLoading, setAddGender) => async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/dropdown/create/gender/v1`;
      const { data } = await axios.post(url, gender, {
        headers: {
          Authorization: localStorage.getItem("vctoken"),
        },
      });
      setLoading(false);
      setAddGender(false);
      dispatch(refetch(true));
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

export const createStudentTypeDropdown =
  (studentType, setLoading, setAddStudent) => async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/dropdown/create/student/type/v1`;
      const { data } = await axios.post(url, studentType, {
        headers: {
          Authorization: localStorage.getItem("vctoken"),
        },
      });
      setLoading(false);
      dispatch(refetch(true));
      setAddStudent(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

export const createSchoolsDropdown =
  (school, setLoading, setAddSchool) => async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/dropdown/create/school/v1`;
      const { data } = await axios.post(url, school, {
        headers: {
          Authorization: localStorage.getItem("vctoken"),
        },
      });
      setLoading(false);
      dispatch(refetch(true));
      setAddSchool(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

export const createGuardianDropdown =
  (guardian, setLoading, setAddGuardian) => async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/dropdown/create/guardian/v1`;
      const { data } = await axios.post(url, guardian, {
        headers: {
          Authorization: localStorage.getItem("vctoken"),
        },
      });
      setLoading(false);
      dispatch(refetch(true));
      setAddGuardian(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

export const deleteGenderDropdown =
  (id, setIsLoading, setDeleteModal, setDropdownType) => async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/dropdown/delete/gender/${id}/v1`;
      const { data } = await axios.delete(url, {
        headers: { Authorization: localStorage.getItem("vctoken") },
      });
      setIsLoading(false);
      setDeleteModal(false);
      setDropdownType(false);
      dispatch(refetch(true));
      toast.success(data.message, {
        position: "top-center",
        autoClose: 5000,
      });
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setDeleteModal(false);
    }
  };
export const deleteStudentTypeDropdown =
  (id, setIsLoading, setDeleteModal, setDropdownType) => async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/dropdown/delete/student/type/${id}/v1`;
      const { data } = await axios.delete(url, {
        headers: { Authorization: localStorage.getItem("vctoken") },
      });
      setIsLoading(false);
      setDeleteModal(false);
      setDropdownType(false);
      dispatch(refetch(true));
      toast.success(data.message, {
        position: "top-center",
        autoClose: 5000,
      });
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setDeleteModal(false);
    }
  };
export const deleteSchoolDropdown =
  (id, setIsLoading, setDeleteModal, setDropdownType) => async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/dropdown/delete/school/${id}/v1`;
      const { data } = await axios.delete(url, {
        headers: { Authorization: localStorage.getItem("vctoken") },
      });
      setIsLoading(false);
      setDeleteModal(false);
      setDropdownType(false);
      dispatch(refetch(true));
      toast.success(data.message, {
        position: "top-center",
        autoClose: 5000,
      });
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setDeleteModal(false);
    }
  };
export const deleteGuardianDropdown =
  (id, setIsLoading, setDeleteModal, setDropdownType) => async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/dropdown/delete/guardian/${id}/v1`;
      const { data } = await axios.delete(url, {
        headers: { Authorization: localStorage.getItem("vctoken") },
      });
      setIsLoading(false);
      setDeleteModal(false);
      setDropdownType(false);
      dispatch(refetch(true));
      toast.success(data.message, {
        position: "top-center",
        autoClose: 5000,
      });
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setDeleteModal(false);
    }
  };

export default adminSlice.reducer;
