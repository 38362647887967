import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const reportSlice = createSlice({
  name: "reports",
  initialState: {
    reports: [],
    reportDetails: [],
    refetch: false,
  },
  reducers: {
    getAllReportListSuccess: (state, action) => {
      state.reports = action.payload;
    },
    getReportDetailsSuccess: (state, action) => {
      state.reportDetails = action.payload;
    },
    refetch: (state, action) => {
      state.refetch = action.payload;
    },
  },
});

export const { getAllReportListSuccess, getReportDetailsSuccess, refetch } =
  reportSlice.actions;

export const getAllReportsList = (setIsLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/report/list/v1`;
    const { data } = await axios.get(url, {
      headers: {
        Authorization: localStorage.getItem("vctoken"),
      },
    });
    dispatch(getAllReportListSuccess(data.data));
    setIsLoading(false);
  } catch (error) {
    console.log(error);
    setIsLoading(false);
  }
};

export const getReportDetails = (id, setLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/report/details/${id}/v1`;
    const { data } = await axios.get(url, {
      headers: {
        Authorization: localStorage.getItem("vctoken"),
      },
    });
    dispatch(getReportDetailsSuccess(data.data));
    setLoading(false);
  } catch (error) {
    console.log(error);
    setLoading(false);
  }
};

export default reportSlice.reducer;
