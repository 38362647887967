import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const eventSlice = createSlice({
  name: "event",
  initialState: {
    events: [],
    eventDetails: {},
    refetch: false,
  },
  reducers: {
    getEventListSuccess: (state, action) => {
      state.events = action.payload;
    },
    refetch: (state, action) => {
      state.refetch = action.payload;
    },
    getEventDetailsSuccess: (state, action) => {
      state.eventDetails = action.payload;
    },
  },
});

export const { getEventListSuccess, refetch, getEventDetailsSuccess } =
  eventSlice.actions;

export const getAllEvents = (setIsLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/event/admin/get/list/v1`;
    const { data } = await axios.get(url, {
      headers: {
        Authorization: localStorage.getItem("vctoken"),
      },
    });
    dispatch(getEventListSuccess(data.data));
    setIsLoading(false);
  } catch (error) {
    console.log(error);
    setIsLoading(false);
  }
};

export const getEventDetails = (id, setLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/event/admin/get/details/${id}/v1`;
    const { data } = await axios.get(url, {
      headers: {
        Authorization: localStorage.getItem("vctoken"),
      },
    });
    dispatch(getEventDetailsSuccess(data.data));
    setLoading(false);
  } catch (error) {
    console.log(error);
    setLoading(false);
  }
};

export const createEvent =
  (formData, setLoading, setModalShow, setFormData, setOpenMap) =>
  async (dispatch) => {
    try {
      dispatch(refetch(false));
      const file = formData.eventImage;
      if (file) {
        const urlPre = `${process.env.REACT_APP_API_BASE_URL}/event/upload/event/image/v1`;
        const responsePre = await axios.post(
          urlPre,
          {
            sFileName: file.name,
            sContentType: file.type,
          },
          {
            headers: {
              Authorization: localStorage.getItem("vctoken"),
            },
          }
        );

        const { sUrl, sPath } = responsePre.data.data;
        formData.eventImage = sPath;

        axios.put(sUrl, file, {
          headers: {
            "Content-Type": file.sContentType,
          },
        });
      }
      const url = `${process.env.REACT_APP_API_BASE_URL}/event/create/v1`;
      const response = await axios.post(url, formData, {
        headers: {
          Authorization: localStorage.getItem("vctoken"),
        },
      });
      setLoading(false);
      setModalShow(false);
      setOpenMap(false);
      setFormData({
        eventImage: null,
        eventName: "",
        eventDateAndTime: "",
        location: "",
        locationName: "",
        shortDescription: "",
        longDescription: "",
        eventType: "",
        userType: ["all"],
      });
      dispatch(refetch(true));
      toast.success(response.data.message, {
        position: "top-center",
        autoClose: 5000,
      });
    } catch (error) {
      console.log(error);
    }
  };

export const updateEvent =
  (
    id,
    formData,
    setLoading,
    setModalShow,
    setFormData,
    setForUpdate,
    setOpenMap
  ) =>
  async (dispatch) => {
    try {
      dispatch(refetch(false));
      if (typeof formData.eventImage !== "string") {
        const file = formData.eventImage ? formData.eventImage : undefined;
        if (file) {
          const urlPre = `${process.env.REACT_APP_API_BASE_URL}/event/upload/event/image/v1`;
          const responsePre = await axios.post(
            urlPre,
            {
              sFileName: file.name,
              sContentType: file.type,
            },
            {
              headers: {
                Authorization: localStorage.getItem("vctoken"),
              },
            }
          );

          const { sUrl, sPath } = responsePre.data.data;
          formData.eventImage = sPath;

          axios.put(sUrl, file, {
            headers: {
              "Content-Type": file.sContentType,
            },
          });
        }
      }
      const url = `${process.env.REACT_APP_API_BASE_URL}/event/update/${id}/v1`;
      const response = await axios.put(url, formData, {
        headers: {
          Authorization: localStorage.getItem("vctoken"),
        },
      });
      setLoading(false);
      setModalShow(false);
      setForUpdate(false);
      setOpenMap(false);
      setFormData({
        eventImage: null,
        eventName: "",
        eventDateAndTime: "",
        location: "",
        locationName: "",
        shortDescription: "",
        longDescription: "",
        eventType: "",
        userType: ["all"],
      });
      dispatch(refetch(true));
      toast.success(response.data.message, {
        position: "top-center",
        autoClose: 5000,
      });
    } catch (error) {
      console.log(error);
    }
  };

export const deleteEvent =
  (id, setIsLoading, setDeleteModal) => async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/event/delete/${id}/v1`;
      const { data } = await axios.delete(url, {
        headers: { Authorization: localStorage.getItem("vctoken") },
      });
      setIsLoading(false);
      setDeleteModal(false);
      dispatch(refetch(true));
      toast.success(data.message, {
        position: "top-center",
        autoClose: 5000,
      });
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setDeleteModal(false);
    }
  };

export const changeEventStatus =
  (id, setLoading, setModalShow) => async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/event/update/status/${id}/v1`;
      const { data } = await axios.put(
        url,
        {},
        {
          headers: {
            Authorization: localStorage.getItem("vctoken"),
          },
        }
      );
      dispatch(refetch(true));
      setLoading(false);
      setModalShow(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setModalShow(false);
    }
  };

export const uploadEventVideos =
  (id, formDataVdieo, setLoading, setAddVideo, setFormDataVideo) =>
  async (dispatch) => {
    try {
      dispatch(refetch(false));
      if (typeof formDataVdieo.eventVideo !== "string") {
        const file = formDataVdieo.eventVideo
          ? formDataVdieo.eventVideo
          : undefined;
        if (file) {
          const urlPre = `${process.env.REACT_APP_API_BASE_URL}/event/upload/event/video/v1`;
          const responsePre = await axios.post(
            urlPre,
            {
              sFileName: file.name,
              sContentType: file.type,
            },
            {
              headers: {
                Authorization: localStorage.getItem("vctoken"),
              },
            }
          );

          const { sUrl, sPath } = responsePre.data.data;
          formDataVdieo.eventVideo = sPath;

          axios.put(sUrl, file, {
            headers: {
              "Content-Type": file.sContentType,
            },
          });
        }
      }
      const url = `${process.env.REACT_APP_API_BASE_URL}/event/update/video/${id}/v1`;
      const response = await axios.put(url, formDataVdieo, {
        headers: {
          Authorization: localStorage.getItem("vctoken"),
        },
      });
      setLoading(false);
      setAddVideo(false);
      setFormDataVideo({
        eventVideo: null,
      });
      dispatch(refetch(true));
      toast.success(response.data.message, {
        position: "top-center",
        autoClose: 5000,
      });
    } catch (error) {
      console.log(error);
    }
  };

export const uploadEventImages =
  (id, formData, setLoading, setAddImage, setFormData) => async (dispatch) => {
    try {
      dispatch(refetch(false));
      if (typeof formData.eventCoverImage !== "string") {
        const file = formData.eventCoverImage
          ? formData.eventCoverImage
          : undefined;
        if (file) {
          const urlPre = `${process.env.REACT_APP_API_BASE_URL}/event/upload/event/cover/image/v1`;
          const responsePre = await axios.post(
            urlPre,
            {
              sFileName: file.name,
              sContentType: file.type,
            },
            {
              headers: {
                Authorization: localStorage.getItem("vctoken"),
              },
            }
          );

          const { sUrl, sPath } = responsePre.data.data;
          formData.eventCoverImage = sPath;

          axios.put(sUrl, file, {
            headers: {
              "Content-Type": file.sContentType,
            },
          });
        }
      }
      const url = `${process.env.REACT_APP_API_BASE_URL}/event/update/image/${id}/v1`;
      const response = await axios.put(url, formData, {
        headers: {
          Authorization: localStorage.getItem("vctoken"),
        },
      });
      setLoading(false);
      setAddImage(false);
      setFormData({
        eventCoverImage: null,
      });
      dispatch(refetch(true));
      toast.success(response.data.message, {
        position: "top-center",
        autoClose: 5000,
      });
    } catch (error) {
      console.log(error);
    }
  };

export const deleteEventCoverImage =
  (id, removeImage, setIsLoading, setRemoveModal, setRemoveImage) =>
  async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/event/remove/image/${id}/v1`;
      const { data } = await axios.put(
        url,
        { removeImage },
        {
          headers: { Authorization: localStorage.getItem("vctoken") },
        }
      );
      setIsLoading(false);
      setRemoveModal(false);
      setRemoveImage("");
      dispatch(refetch(true));
      toast.success(data.message, {
        position: "top-center",
        autoClose: 5000,
      });
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setRemoveModal(false);
      setRemoveImage("");
    }
  };

export const deleteEventVideo =
  (id, removeVideo, setIsLoading, setRemoveModal, setRemoveVideo) =>
  async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/event/remove/video/${id}/v1`;
      const { data } = await axios.put(
        url,
        { removeVideo },
        {
          headers: { Authorization: localStorage.getItem("vctoken") },
        }
      );
      setIsLoading(false);
      setRemoveModal(false);
      setRemoveVideo("");
      dispatch(refetch(true));
      toast.success(data.message, {
        position: "top-center",
        autoClose: 5000,
      });
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setRemoveModal(false);
      setRemoveVideo("");
    }
  };

export default eventSlice.reducer;
