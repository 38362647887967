import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import NavLink from "../components/NavLink";
import {
  Chats,
  ChartLine,
  Files,
  FlagBanner,
  MegaphoneSimple,
  Ticket,
  Users,
  UsersThree,
  UsersFour,
  AddressBook,
  UserGear,
  VideoCamera,
  ReceiptX,
} from "@phosphor-icons/react";
import { IoSettingsOutline } from "react-icons/io5";

const sidebarData = [
  {
    commonUrl: "/",
    primary: {
      iconClass: <ChartLine size={18} />,
      title: "Dashboard",
      url: "/",
    },
  },
  {
    commonUrl: "/users",
    primary: {
      iconClass: <Users size={18} />,
      title: "Users",
      url: "/users",
    },
  },
  {
    commonUrl: "/banners",
    primary: {
      iconClass: <FlagBanner size={18} />,
      title: "Banner",
      url: "/banners",
    },
  },
  {
    commonUrl: "/events",
    primary: {
      iconClass: <Ticket size={18} />,
      title: "Events",
      url: "/events",
    },
  },
  {
    commonUrl: "/announcements",
    primary: {
      iconClass: <MegaphoneSimple size={18} strokeWidth={1} />,
      title: "Accouncement",
      url: "/announcements",
    },
  },
  {
    commonUrl: "/groups",
    primary: {
      iconClass: <UsersFour size={18} />,
      title: "Group Chats",
      url: "/groups",
    },
  },
  {
    commonUrl: "/marko-polo",
    primary: {
      iconClass: <VideoCamera size={18} />,
      title: "Video-Connect",
      url: "/marko-polo",
    },
  },
  {
    commonUrl: "/mini-groups",
    primary: {
      iconClass: <UsersThree size={18} />,
      title: "Mini-Group Chats",
      url: "/mini-groups",
    },
  },
  {
    commonUrl: "/chats",
    primary: {
      iconClass: <Chats size={18} />,
      title: "Chats",
      url: "/chats",
    },
  },
  {
    commonUrl: "/reports",
    primary: {
      iconClass: <ReceiptX size={18} />,
      title: "Reports",
      url: "/reports",
    },
  },
  {
    commonUrl: "/contactUs",
    primary: {
      iconClass: <AddressBook size={18} />,
      title: "Contact-Us",
      url: "/contactUs",
    },
  },
  {
    commonUrl: "/admin-settings",
    primary: {
      iconClass: <UserGear size={18} />,
      title: "Admin Settings",
      url: "/admin-settings",
    },
  },
  {
    commonUrl: "/app-setting",
    primary: {
      iconClass: <IoSettingsOutline strokeWidth={2} size={18} />,
      title: "App Setting",
      url: "/app-setting",
    },
  },
  {
    commonUrl: "/other",
    primary: {
      iconClass: <Files size={18} />,
      title: "Other Pages",
      url: "/other",
    },
  },
];

const Sidebar = () => {
  const location = useLocation();
  const [menuState, setMenuState] = useState({});
  const [bodyClass, setBodyClass] = useState("sidebar-icon-only");

  const toggleMenuState = (menuStateKey) => {
    setMenuState((prevState) => ({
      ...Object.keys(prevState).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {}),
      [menuStateKey]: !prevState[menuStateKey],
    }));
  };

  const onRouteChanged = () => {
    document.querySelector("#sidebar").classList.remove("active");
    setMenuState({});
  };

  const isPathActive = (path) => {
    return location.pathname.startsWith(path);
  };

  useEffect(() => {
    onRouteChanged();
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", () => {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", () => {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });

    return () => {
      // Clean up event listeners if needed
    };
  }, [location]);

  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
      <ul className="nav">
        {sidebarData.map((data) => {
          return (
            <NavLink
              isPathActive={isPathActive}
              url={data.primary.url}
              iconClass={data.primary.iconClass}
              title={data.primary.title}
              commonUrl={data.commonUrl}
              child={data.secondary}
            />
          );
        })}
      </ul>
    </nav>
  );
};

export default Sidebar;
