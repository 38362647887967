import React, { useEffect, useRef, useState } from "react";
import { Trash } from "@phosphor-icons/react";
import { Col, FormGroup, Modal, ModalBody, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  createGenderDropdown,
  createGuardianDropdown,
  createSchoolsDropdown,
  createStudentTypeDropdown,
  deleteGenderDropdown,
  deleteGuardianDropdown,
  deleteSchoolDropdown,
  deleteStudentTypeDropdown,
  getAllDropdowns,
} from "../../../../redux/slices/adminSlice";

const IMG_URL = process.env.REACT_APP_AWS_IMAGE_URL;

function AdminSettings() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [addGender, setAddGender] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [addStudent, setAddStudent] = useState(false);
  const [addGuardian, setAddGuardian] = useState(false);
  const [addSchool, setAddSchool] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [id, setId] = useState("");
  const [dropdownType, setDropdownType] = useState("");

  const [gender, setGender] = useState({
    key: "",
  });
  const [school, setSchool] = useState({
    key: "",
  });
  const [studentType, setStudentType] = useState({
    key: "",
  });
  const [guardian, setGuardian] = useState({
    key: "",
  });

  const { dropdowns, refetch } = useSelector((state) => state.adminSlice);

  const prevProps = useRef({ refetch }).current;

  useEffect(() => {
    if (prevProps.refetch != refetch) {
      setIsLoading(true);
      dispatch(getAllDropdowns(setIsLoading));
    }
    return () => {
      prevProps.refetch = refetch;
    };
  }, [refetch]);

  useEffect(() => {
    setIsLoading(true);
    dispatch(getAllDropdowns(setIsLoading));
  }, []);

  const addGenderDropdown = () => {
    setIsLoading(true);
    dispatch(createGenderDropdown(gender, setIsLoading, setAddGender));
  };
  const addSchoolDropdown = () => {
    setIsLoading(true);
    dispatch(createSchoolsDropdown(school, setIsLoading, setAddSchool));
  };
  const addStudentTypeDropdown = () => {
    setIsLoading(true);
    dispatch(
      createStudentTypeDropdown(studentType, setIsLoading, setAddStudent)
    );
  };
  const addGuardianDropdown = () => {
    setIsLoading(true);
    dispatch(createGuardianDropdown(guardian, setIsLoading, setAddGuardian));
  };

  const handleDelete = () => {
    if (dropdownType === "gender") {
      setIsLoading(true);
      dispatch(
        deleteGenderDropdown(id, setIsLoading, setDeleteModal, setDropdownType)
      );
    } else if (dropdownType === "studentType") {
      setIsLoading(true);
      dispatch(
        deleteStudentTypeDropdown(
          id,
          setIsLoading,
          setDeleteModal,
          setDropdownType
        )
      );
    } else if (dropdownType === "guardian") {
      setIsLoading(true);
      dispatch(
        deleteGuardianDropdown(
          id,
          setIsLoading,
          setDeleteModal,
          setDropdownType
        )
      );
    } else if (dropdownType === "school") {
      setIsLoading(true);
      dispatch(
        deleteSchoolDropdown(id, setIsLoading, setDeleteModal, setDropdownType)
      );
    }
  };

  const handleInputChange = (e, type) => {
    if (type === "gender") {
      const value = e.target.value;
      const filteredValue = value.replace(/[0-9]/g, "");
      setGender({ key: filteredValue });
    }
    if (type === "studentType") {
      const value = e.target.value;
      const filteredValue = value.replace(/[0-9]/g, "");
      setStudentType({ key: filteredValue });
    }
    if (type === "guardian") {
      const value = e.target.value;
      const filteredValue = value.replace(/[0-9]/g, "");
      setGuardian({ key: filteredValue });
    }
    if (type === "school") {
      const value = e.target.value;
      const filteredValue = value.replace(/[0-9]/g, "");
      setSchool({ key: filteredValue });
    }
  };

  return (
    <>
      {isLoading ? (
        <div className="w-100 d-flex">
          <div class="spinner-border text-primary mx-auto" role="status"></div>
        </div>
      ) : (
        <div>
          <div className="row">
            <div className="col-lg-6 grid-margin stretch-card mb-4">
              <div className="card">
                <div className="card-body">
                  <div className="card_heading">
                    <div className="title_text">Gender List</div>
                    <button
                      className="btn_primary_outline ms-auto"
                      onClick={() => setAddGender(true)}
                    >
                      Add New Gender
                    </button>
                  </div>
                  <table className="table table-bordered mt-3">
                    <thead>
                      <tr>
                        <th>Gender</th>
                        <th width="50px" className="text-center">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {dropdowns?.genders?.map((g) => {
                        return (
                          <tr>
                            <td>{g.key}</td>
                            <td className="text-center">
                              <Trash
                                size={18}
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setDeleteModal(true);
                                  setId(g._id);
                                  setDropdownType("gender");
                                }}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <Modal
              show={addGender}
              size="sx"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <ModalBody>
                <h5 className="mb-3">Add Gender</h5>

                <FormGroup className="mb-3">
                  <label>Gender</label>
                  <input
                    type="text"
                    placeholder="Add Gender"
                    value={gender.key}
                    className="form-control"
                    onChange={(e) => handleInputChange(e, "gender")}
                    required={true}
                  />
                </FormGroup>
              </ModalBody>
              <Modal.Footer>
                <button
                  type="button"
                  className="btn_secondary"
                  onClick={() => {
                    setGender({
                      key: "",
                    });
                    setAddGender(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn_primary"
                  onClick={addGenderDropdown}
                >
                  Add
                </button>
              </Modal.Footer>
            </Modal>

            <div className="col-lg-6 grid-margin stretch-card mb-4">
              <div className="card">
                <div className="card-body">
                  <div className="card_heading">
                    <div className="title_text">Student Type List</div>
                    <button
                      className="btn_primary_outline ms-auto"
                      onClick={() => setAddStudent(true)}
                    >
                      Add New Student
                    </button>
                  </div>
                  <table className="table table-bordered mt-3">
                    <thead>
                      <tr>
                        <th>Student</th>
                        <th width="50px" className="text-center">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {dropdowns?.studentsType?.map((g) => {
                        return (
                          <tr>
                            <td>{g.key}</td>
                            <td className="text-center">
                              <Trash
                                size={18}
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setDeleteModal(true);
                                  setId(g._id);
                                  setDropdownType("studentType");
                                }}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <Modal
              show={addStudent}
              size="sx"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <ModalBody>
                <h5 className="mb-3">Add Student</h5>

                <FormGroup className="mb-3">
                  <label>Student</label>
                  <input
                    type="text"
                    placeholder="Add Student"
                    value={studentType.key}
                    className="form-control"
                    onChange={(e) => handleInputChange(e, "studentType")}
                    required={true}
                  />
                </FormGroup>
              </ModalBody>
              <Modal.Footer>
                <button
                  type="button"
                  className="btn_secondary"
                  onClick={() => {
                    setStudentType({
                      key: "",
                    });
                    setAddStudent(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn_primary"
                  onClick={addStudentTypeDropdown}
                >
                  Add
                </button>
              </Modal.Footer>
            </Modal>

            <div className="col-lg-6 grid-margin stretch-card mb-4">
              <div className="card">
                <div className="card-body">
                  <div className="card_heading">
                    <div className="title_text">Guardian List</div>
                    <button
                      className="btn_primary_outline ms-auto"
                      onClick={() => setAddGuardian(true)}
                    >
                      Add New Guardian
                    </button>
                  </div>
                  <table className="table table-bordered mt-3">
                    <thead>
                      <tr>
                        <th>Guardian</th>
                        <th width="50px" className="text-center">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {dropdowns?.guardians?.map((g) => {
                        return (
                          <tr>
                            <td>{g.key}</td>
                            <td className="text-center">
                              <Trash
                                size={18}
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setDeleteModal(true);
                                  setId(g._id);
                                  setDropdownType("guardian");
                                }}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <Modal
              show={addGuardian}
              size="sx"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <ModalBody>
                <h5 className="mb-3">Add Guardian</h5>

                <FormGroup className="mb-3">
                  <label>Guardian</label>
                  <input
                    type="text"
                    placeholder="Add guardian"
                    value={guardian.key}
                    className="form-control"
                    onChange={(e) => handleInputChange(e, "guardian")}
                    required={true}
                  />
                </FormGroup>
              </ModalBody>
              <Modal.Footer>
                <button
                  type="button"
                  className="btn_secondary"
                  onClick={() => {
                    setGuardian({
                      key: "",
                    });
                    setAddGuardian(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn_primary"
                  onClick={addGuardianDropdown}
                >
                  Add
                </button>
              </Modal.Footer>
            </Modal>

            <div className="col-lg-6 grid-margin stretch-card mb-4">
              <div className="card">
                <div className="card-body">
                  <div className="card_heading">
                    <div className="title_text">School Name List</div>
                    <button
                      className="btn_primary_outline ms-auto"
                      onClick={() => setAddSchool(true)}
                    >
                      Add New School
                    </button>
                  </div>
                  <table className="table table-bordered mt-3">
                    <thead>
                      <tr>
                        <th>School</th>
                        <th width="50px" className="text-center">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {dropdowns?.schools?.map((g) => {
                        return (
                          <tr>
                            <td>{g.key}</td>
                            <td className="text-center">
                              <Trash
                                size={18}
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setDeleteModal(true);
                                  setId(g._id);
                                  setDropdownType("school");
                                }}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <Modal
              show={addSchool}
              size="sx"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <ModalBody>
                <h5 className="mb-3">Add School</h5>

                <FormGroup className="mb-3">
                  <label>School</label>
                  <input
                    type="text"
                    placeholder="Add school"
                    value={school.key}
                    className="form-control"
                    onChange={(e) => handleInputChange(e, "school")}
                    required={true}
                  />
                </FormGroup>
              </ModalBody>
              <Modal.Footer>
                <button
                  type="button"
                  className="btn_secondary"
                  onClick={() => {
                    setSchool({
                      key: "",
                    });
                    setAddSchool(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn_primary"
                  onClick={addSchoolDropdown}
                >
                  Add
                </button>
              </Modal.Footer>
            </Modal>
          </div>
          <Modal
            show={deleteModal}
            size="sx"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <ModalBody>
              <h5 className="mb-3">Are you sure you want to Delete ?</h5>
            </ModalBody>
            <Modal.Footer>
              <button
                type="button"
                className="btn_secondary"
                onClick={() => {
                  setDeleteModal(false);
                  setId("");
                  setDropdownType("");
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn_primary"
                onClick={handleDelete}
              >
                Delete
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </>
  );
}

export default AdminSettings;
