import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./slices/userSlice";
import authSlice from "./slices/authSlice";
import bannerSlice from "./slices/bannerSlice";
import announcementSlice from "./slices/announcementSlice";
import appsettingSlice from "./slices/appsettingSlice";
import contactUsSlice from "./slices/contactusSlice";
import eventSlice from "./slices/eventSlice";
import adminSlice from "./slices/adminSlice";
import groupSlice from "./slices/groupSlice";
import miniGroupSlice from "./slices/miniGroupSlice";
import singleChatSlice from "./slices/singleChatSlice";
import otherSlice from "./slices/otherSlice";
import dashboardSlice from "./slices/dashboardSlice";
import reportSlice from "./slices/reportSlice";

export default configureStore({
  reducer: {
    userSlice,
    authSlice,
    bannerSlice,
    announcementSlice,
    appsettingSlice,
    contactUsSlice,
    eventSlice,
    adminSlice,
    groupSlice,
    miniGroupSlice,
    singleChatSlice,
    otherSlice,
    dashboardSlice,
    reportSlice,
  },
});
