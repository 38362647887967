import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getContactDetails } from "../../../../redux/slices/contactusSlice";

function ContactDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();

  const { contactDetails, refetch } = useSelector(
    (state) => state.contactUsSlice
  );

  const prevProps = useRef({ refetch }).current;

  useEffect(() => {
    if (prevProps.refetch != refetch) {
      setIsLoading(true);
      dispatch(getContactDetails(id, setIsLoading));
    }
    return () => {
      prevProps.refetch = refetch;
    };
  }, [refetch]);

  useEffect(() => {
    setIsLoading(true);
    dispatch(getContactDetails(id, setIsLoading));
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="w-100 d-flex">
          <div class="spinner-border text-primary mx-auto" role="status"></div>
        </div>
      ) : (
        <div>
          <Row className="d-flex justify-content-center">
            <Col md={5} className="">
              <div className="card">
                <div className="card-body">
                  <Row>
                    <Col md={12} className="profile_info px-4 pt-1">
                      <div>
                        <span>Email :</span>
                        <span>{contactDetails.email}</span>
                      </div>
                      <div>
                        <span>Subject :</span>
                        <span>{contactDetails.subject}</span>
                      </div>
                      <div>
                        <span>Description :</span>
                        <span>{contactDetails.description}</span>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
}

export default ContactDetails;
