import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Card, Image, ListGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getReportDetails } from "../../../../redux/slices/reportSlice";

const IMG_URL = process.env.REACT_APP_AWS_IMAGE_URL;

const ReportDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();

  const { reportDetails, refetch } = useSelector((state) => state.reportSlice);

  const prevProps = useRef({ refetch }).current;

  useEffect(() => {
    if (prevProps.refetch !== refetch) {
      setIsLoading(true);
      dispatch(getReportDetails(id, setIsLoading));
    }
    return () => {
      prevProps.refetch = refetch;
    };
  }, [refetch]);

  useEffect(() => {
    setIsLoading(true);
    dispatch(getReportDetails(id, setIsLoading));
  }, []);

  const renderMemberList = () => {
    return reportDetails?.[0]?.chatDetails?.[0]?.memberDetails?.map(
      (member, index) => (
        <ListGroup.Item
          key={index}
          className="d-flex align-items-center"
          style={{ cursor: "pointer" }}
          onClick={() => navigate(`/user/${member._id}`)}
        >
          <Image
            src={`${IMG_URL}${member.userImage}`}
            roundedCircle
            style={{ width: "40px", height: "40px", marginRight: "15px" }}
          />
          <div>
            <div>
              {member.firstName} {member.lastName}
            </div>
            <div className="text-muted" style={{ fontSize: "12px" }}>
              {member.whichBestDescribeYou}
            </div>
          </div>
        </ListGroup.Item>
      )
    );
  };

  return (
    <>
      {isLoading ? (
        <div className="w-100 d-flex">
          <div
            className="spinner-border text-primary mx-auto"
            role="status"
          ></div>
        </div>
      ) : (
        <div className="container mt-4">
          <Row className="d-flex justify-content-center">
            <Col md={6}>
              <Card className="shadow-sm">
                <Card.Body>
                  <h5 className="mb-3">Report Details</h5>
                  <div className="mb-3">
                    <strong>Reported On:</strong>{" "}
                    <span>
                      {new Date(reportDetails?.[0]?.createdAt)
                        .toLocaleDateString("en-US", {
                          month: "2-digit",
                          day: "2-digit",
                          year: "numeric",
                        })
                        .replace(/\//g, "-")}
                    </span>
                  </div>
                  <div className="mb-3">
                    <strong>Chat-Id:</strong>{" "}
                    <span
                      onClick={() => {
                        const chatId =
                          reportDetails?.[0]?.chatDetails?.[0]?._id;

                        if (
                          reportDetails?.[0]?.chatDetails?.[0]?.isAdminGroup
                        ) {
                          navigate(`/group/${chatId}`);
                        } else if (
                          reportDetails?.[0]?.chatDetails?.[0]?.isMiniGroup
                        ) {
                          navigate(`/mini-group/${chatId}`);
                        } else {
                          navigate(`/chat/${chatId}`);
                        }
                      }}
                      style={{ cursor: "pointer", color: "#e8783d" }}
                    >
                      {reportDetails?.[0]?.chatDetails?.[0]?._id}
                    </span>
                  </div>
                  <div className="mb-3">
                    <strong>Group Name or Type:</strong>{" "}
                    <span>
                      {reportDetails?.[0]?.chatDetails?.[0]?.isAdminGroup
                        ? `${reportDetails?.[0]?.chatDetails?.[0]?.groupName} (Group)`
                        : reportDetails?.[0]?.chatDetails?.[0]?.isMiniGroup
                        ? "Mini-Group"
                        : "One-to-One Chat"}
                    </span>
                  </div>
                  <div className="mb-3">
                    <strong>Reason:</strong>{" "}
                    <span>{reportDetails?.[0]?.reason}</span>
                  </div>
                  <div className="mb-3">
                    <strong>Reported By:</strong>{" "}
                    <span>
                      {reportDetails?.[0]?.reportByUserDetails?.[0]?.firstName}{" "}
                      {reportDetails?.[0]?.reportByUserDetails?.[0]?.lastName}
                    </span>
                  </div>
                </Card.Body>
              </Card>
              <Card className="mt-4 shadow-sm">
                <Card.Body>
                  <h5 className="mb-3">Members</h5>
                  <ListGroup>{renderMemberList()}</ListGroup>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default ReportDetails;
