import React, { Suspense, lazy, useEffect, useState } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import Spinner from "../app/views/shared/Spinner";
import { useDispatch } from "react-redux";
import ContactDetails from "./views/pages/contactUs/ContactDetails";
import AdminSettings from "./views/pages/admin-settings/AdminSettings";
import { getAdminDetail } from "../redux/slices/userSlice";
import Policy from "./views/pages/other/Policy";
import AboutUs from "./views/pages/other/AboutUs";
import HelpAndFaq from "./views/pages/other/HelpAndFaq";
import TNC from "./views/pages/other/TNC";
import Report from "./views/pages/report/Report";
import ReportDetails from "./views/pages/report/ReportDetails";
// import MarkoPolo from "./views/pages/marko-polo/MarkoPolo";
// import MarkoPoloDetails from "./views/pages/marko-polo/MarkoPoloDetails";

const Dashboard = lazy(() => import("./views/pages/dashboard/Dashboard"));
const Profile = lazy(() => import("./views/pages/Profile"));
const Error404 = lazy(() => import("./views/pages/error/Error404"));
const Error500 = lazy(() => import("./views/pages/error/Error500"));
const Auth = lazy(() => import("./views/pages/auth/Auth"));
const Users = lazy(() => import("./views/pages/user/Users"));
const AppSetting = lazy(() => import("./views/pages/AppSetting"));
const UserDetail = lazy(() => import("./views/pages/user/UserDetail"));
const Banner = lazy(() => import("./views/pages/banner/Banner"));
const BannerDetail = lazy(() => import("./views/pages/banner/BannerDetail"));
const Event = lazy(() => import("./views/pages/event/Event"));
const EventDetail = lazy(() => import("./views/pages/event/EventDetail"));
const Announcement = lazy(() =>
  import("./views/pages/announcement/Announcement")
);
const AnnouncementDetail = lazy(() =>
  import("./views/pages/announcement/AnnouncementDetail")
);
const Other = lazy(() => import("./views/pages/other/Other"));
const OtherDetail = lazy(() => import("./views/pages/other/OtherDetail"));
const GroupChat = lazy(() => import("./views/pages/group-chat/GroupChat"));
const GroupChatDetail = lazy(() =>
  import("./views/pages/group-chat/GroupChatDetail")
);
const MarkoPolo = lazy(() => import("./views/pages/marko-polo/MarkoPolo"));
const MarkoPoloDetails = lazy(() =>
  import("./views/pages/marko-polo/MarkoPoloDetails")
);
const MiniGroupChat = lazy(() =>
  import("./views/pages/mini-group-chat/MiniGroupChat")
);
const MiniGroupChatDetail = lazy(() =>
  import("./views/pages/mini-group-chat/MiniGroupChatDetail")
);
const Chats = lazy(() => import("./views/pages/chat/Chats"));
const ChatDetail = lazy(() => import("./views/pages/chat/ChatDetail"));
const ContactUs = lazy(() => import("./views/pages/contactUs/ContactUs"));

const SecureRoute = ({ redirectPath = "/login", children }) => {
  // !localStorage.getItem("sgtoken") ? <Navigate to={redirectPath} /> : children;
  if (!localStorage.getItem("vctoken")) {
    return <Navigate to={redirectPath} replace />;
  }
  return children;
};

const AppRoutes = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("vctoken");

  useEffect(() => {
    if (token) {
      dispatch(getAdminDetail(setLoading));
    }
  }, [location, token]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Suspense fallback={<Spinner />}>
      <Routes>
        <Route path="/login" element={<Auth />} />{" "}
        <Route path="/forgot_password" element={<Auth />} />
        <Route path="/reset_password/:resetToken" element={<Auth />} />
        <Route
          path="/change_password"
          element={
            <SecureRoute>
              <Auth />
            </SecureRoute>
          }
        />
        <Route
          path="/"
          element={
            <SecureRoute>
              <Dashboard />
            </SecureRoute>
          }
        />
        <Route
          path="/users"
          element={
            <SecureRoute>
              <Users />
            </SecureRoute>
          }
        />
        <Route
          path="/user/:id"
          element={
            <SecureRoute>
              <UserDetail />
            </SecureRoute>
          }
        />
        <Route
          path="/banners"
          element={
            <SecureRoute>
              <Banner />
            </SecureRoute>
          }
        />
        <Route
          path="/banner/:id"
          element={
            <SecureRoute>
              <BannerDetail />
            </SecureRoute>
          }
        />
        <Route
          path="/events"
          element={
            <SecureRoute>
              <Event />
            </SecureRoute>
          }
        />
        <Route
          path="/event/:id"
          element={
            <SecureRoute>
              <EventDetail />
            </SecureRoute>
          }
        />
        <Route
          path="/announcements"
          element={
            <SecureRoute>
              <Announcement />
            </SecureRoute>
          }
        />
        <Route
          path="/announcement/:id"
          element={
            <SecureRoute>
              <AnnouncementDetail />
            </SecureRoute>
          }
        />
        <Route
          path="/app-setting"
          element={
            <SecureRoute>
              <AppSetting />
            </SecureRoute>
          }
        />
        <Route path="/other" element={<Other />} />
        <Route path="/other/help" element={<OtherDetail forHelp />} />
        <Route path="/other/terms&condition" element={<OtherDetail forTNC />} />
        <Route
          path="/other/privacypolicy"
          element={<OtherDetail forPolicy />}
        />
        <Route path="/other/aboutus" element={<OtherDetail forAbout />} />
        <Route
          path="/groups"
          element={
            <SecureRoute>
              <GroupChat />
            </SecureRoute>
          }
        />
        <Route
          path="/group/:id"
          element={
            <SecureRoute>
              <GroupChatDetail />
            </SecureRoute>
          }
        />
        <Route
          path="/marko-polo"
          element={
            <SecureRoute>
              <MarkoPolo />
            </SecureRoute>
          }
        />
        <Route
          path="/markos-polo/:id"
          element={
            <SecureRoute>
              <MarkoPoloDetails />
            </SecureRoute>
          }
        />
        <Route
          path="/mini-groups"
          element={
            <SecureRoute>
              <MiniGroupChat />
            </SecureRoute>
          }
        />
        <Route
          path="/mini-group/:id"
          element={
            <SecureRoute>
              <MiniGroupChatDetail />
            </SecureRoute>
          }
        />
        <Route
          path="/chats"
          element={
            <SecureRoute>
              <Chats />
            </SecureRoute>
          }
        />
        <Route
          path="/chat/:id"
          element={
            <SecureRoute>
              <ChatDetail />
            </SecureRoute>
          }
        />
        <Route
          path="/reports"
          element={
            <SecureRoute>
              <Report />
            </SecureRoute>
          }
        />
        <Route
          path="/report/:id"
          element={
            <SecureRoute>
              <ReportDetails />
            </SecureRoute>
          }
        />
        <Route
          path="/contactUs"
          element={
            <SecureRoute>
              <ContactUs />
            </SecureRoute>
          }
        />
        <Route
          path="/contactUs/:id"
          element={
            <SecureRoute>
              <ContactDetails />
            </SecureRoute>
          }
        />
        <Route
          path="/admin-settings"
          element={
            <SecureRoute>
              <AdminSettings />
            </SecureRoute>
          }
        />
        <Route
          exact
          path="/profile"
          element={
            <SecureRoute>
              <Profile />
            </SecureRoute>
          }
        />
        <Route path="/404" element={<Error404 />} />
        <Route path="/privacyPolicy" element={<Policy />} />
        <Route path="/aboutUs" element={<AboutUs />} />
        <Route path="/helpAndFaq" element={<HelpAndFaq />} />
        <Route path="/termsAndCondition" element={<TNC />} />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
