import React, { useEffect, useState } from "react";
import { Col, Row, Container, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getTerms } from "../../../../redux/slices/otherSlice";

const TNC = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [showTerms, setShowTerms] = useState(true);
  const { term } = useSelector((state) => state.otherSlice);

  useEffect(() => {
    dispatch(getTerms(setIsLoading));
  }, [dispatch]);

  const handleToggle = () => {
    setShowTerms(!showTerms);
  };

  const containerStyle = {
    backgroundColor: "#fff",
  };

  const contentStyle = {
    textAlign: "justify",
    textJustify: "inter-word",
    margin: "0 auto",
    padding: "0 15px",
  };

  return (
    <div style={containerStyle}>
      <Container>
        <Row className="justify-content-center">
          <Col lg={8} md={10} sm={12}>
            <div
              className="auth-form-light text-center py-5 px-4 px-sm-5"
              style={{ backgroundColor: "#fff" }}
            >
              <div className="brand-logo d-flex justify-content-center mb-4">
                <img
                  className="mx-auto"
                  src={require("../../../../assets/images/logo-final.png")}
                  alt="logo"
                  style={{ maxWidth: "100%", height: "auto" }}
                />
              </div>

              <div
                style={contentStyle}
                dangerouslySetInnerHTML={{ __html: term?.terms }}
              ></div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TNC;
